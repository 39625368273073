import request from '@/utils/request'
const goodsserviceApi = {
  list: '/goods/service',
  individualList: '/goods/service/individual',
  combinationCreate: '/goods/service/combination',
  combinationUpdate: id => `/goods/service/combination/${id}`,
  combinationDetail: id => `/goods/service/combination/${id}`,
  individualCreate: '/goods/service/individual',
  individualUpdate: id => `/goods/service/individual/${id}`,
  individualDetail: id => `/goods/service/individual/${id}`,
  remove: id => `goods/service/${id}`,
  switchStatus: id => `goods/service/status/${id}`,
  combinationEdit: id => `goods/service/combination/edit/${id}`
}
export function list(parameter) {
  return request({
    url: goodsserviceApi.list,
    method: 'get',
    params: parameter
  })
}
export function individualList(parameter) {
  return request({
    url: goodsserviceApi.individualList,
    method: 'get',
    params: parameter
  })
}
export function combinationCreate(parameter) {
  return request({
    url: goodsserviceApi.combinationCreate,
    method: 'post',
    data: parameter
  })
}
export function combinationUpdate(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: goodsserviceApi.combinationUpdate(id),
    method: 'put',
    data: parameter
  })
}
export function combinationEdit(parameter) {
  return request({
    url: goodsserviceApi.combinationEdit(parameter),
    method: 'get'
  })
}
export function combinationDetail(parameter) {
  return request({
    url: goodsserviceApi.combinationDetail(parameter),
    method: 'get'
  })
}
export function individualCreate(parameter) {
  return request({
    url: goodsserviceApi.individualCreate,
    method: 'post',
    data: parameter
  })
}
export function individualUpdate(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: goodsserviceApi.individualUpdate(id),
    method: 'put',
    data: parameter
  })
}
export function individualDetail(parameter) {
  return request({
    url: goodsserviceApi.individualDetail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: goodsserviceApi.remove(parameter),
    method: 'delete'
  })
}
export function switchStatus(parameter) {
  return request({
    url: goodsserviceApi.switchStatus(parameter),
    method: 'patch'
  })
}
