<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="输入搜索：">
              <a-input-search
                v-model="query.name"
                placeholder="请输入商品名称"
                style="width: 100%"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="分类：">
              <h-select v-model="query.classification" allowClear placeholder="全部分类" @change="() => initData()">
                <a-select-option v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">{{
                  typeInfo.label
                }}</a-select-option>
              </h-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="状态：">
              <h-select v-model="query.status" allowClear placeholder="全部状态" @change="() => initData()">
                <a-select-option
                  v-for="statusInfo in statusOptions"
                  :key="statusInfo.value"
                  :value="statusInfo.value"
                  >{{ statusInfo.label }}</a-select-option
                >
              </h-select>
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>商品列表</span>
      </div>
      <div slot="extra">
        <a-space>
          <a-button
            v-if="$auth('goods_service_add')"
            type="primary"
            shape="round"
            @click="$router.push('/goods/service/individual/create')"
            ><a-icon type="plus" />上架商品</a-button
          >
          <a-button
            v-if="$auth('goods_service_add')"
            type="primary"
            shape="round"
            @click="$router.push('/goods/service/combination/create')"
            ><a-icon type="plus" />上架组合商品</a-button
          >
        </a-space>
      </div>
      <main-table ref="table" v-if="$auth('goods_service_list')"></main-table>
    </a-card>
  </div>
</template>

<script>
import MainTable from './components/MainTable'
import { GOODS_SKILL_STATUS, SERVICE_MANAGER_TYPE } from '@/store/dictionary'
export default {
  name: 'GoodsManagementServiceList',
  components: {
    MainTable,
  },
  data() {
    return {
      query: {
        classification: undefined,
        name: undefined,
        status: undefined,
      },
      typeOptions: SERVICE_MANAGER_TYPE,
      statusOptions: GOODS_SKILL_STATUS,
    }
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
